import { motion } from "framer-motion";
import { MobileMenuItem } from "./MobileMenuItem";
import DarkModeToggle from "./DarkModeToggle";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Links = ["Home", "Services", "About", "Contact"];

export default function MobileNavBar() {
  return (
    <>
      <div className="bg-slate-200 dark:bg-slate-800 h-screen border-l-4 border-yellow-500">
        <motion.ul variants={variants} className="flex flex-col pl-6 pt-6">
          <li>Waiiki Web Designs</li>
          <hr />
          {Links.map((name, index) => (
            <div className="mt-6 grow">
              <MobileMenuItem key={name} i={index} name={name} />
            </div>
          ))}
          <li className="mt-8 pt-8">
            <DarkModeToggle />
          </li>
        </motion.ul>
      </div>
    </>
  );
}
