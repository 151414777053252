/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useRoutes } from "react-router-dom";

import { publicRoutes } from "./Public";
import { useAppSelector } from "../../api/hooks";

export const AppRoutes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return <>{useRoutes([...publicRoutes])}</>;
};
