export default function MainLogo() {
  return (
    <>
      <div className="mb-4 flex items-center pr-6 font-medium xl:pr-12 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="h-10 w-10 rounded-full bg-indigo-500 p-2 text-white"
          viewBox="0 0 24 24"
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg>
        <span className="ml-3  text-4xl font-bold md:text-xl lg:text-2xl xl:text-4xl">
          Waiiki Web Designs
        </span>
      </div>
    </>
  );
}
