import { useAppDispatch, useAppSelector } from "api/hooks";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useCookies } from "react-cookie";

export default function DarkModeToggle() {
  const darkModeContext = useAppSelector((state) => state.theme);
  const [cookies, setCookie, removeCookie] = useCookies(["dark"]);

  const dispatch = useAppDispatch();

  function setDarkModeAndToggleCheck() {
    let darkMode = !darkModeChecked;
    setDarkModeChecked(darkMode);
    dispatch({ type: "theme/toggleTheme", payload: darkMode });
    setCookie("dark", darkMode);
  }

  const [darkModeChecked, setDarkModeChecked] = useState<boolean>(
    darkModeContext.darkMode
  );
  return (
    <>
      <button
        data-darkmode={darkModeChecked}
        onClick={() => setDarkModeAndToggleCheck()}
      >
        <motion.div layout className="handle">
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.i
              className={`icon far fa-${darkModeChecked ? "moon" : "sun"}`}
              key={darkModeChecked ? "moon" : "sun"}
              initial={{ y: -30, opacity: 0, scale: 1.4 }}
              animate={{ y: 0, opacity: 1, scale: 1.4 }}
              exit={{ y: 30, opacity: 0, scale: 1.4 }}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.2 }}
            />
          </AnimatePresence>
        </motion.div>
      </button>
    </>
  );
}
