import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
// import { Mutex } from "async-mutex";
// import { RootState } from "./stores";

//const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;
// Create a new mutex
// const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: "http://localhost:8080",
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it

  let result = await baseQuery(args, api, extraOptions);
  return result;
};

export default customFetchBase;
