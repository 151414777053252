import { motion, Variants } from "framer-motion";

const buttonVariants: Variants = {
  closed: { opacity: 1 },
  open: { opacity: 0 },
};

interface PathProps {
  d?: string;
  variants: typeof buttonVariants;
  transition?: { duration: number };
}
const Path = (props: PathProps) => (
  <motion.path
    className={"stroke-black dark:stroke-white"}
    strokeWidth="3"
    strokeLinecap="round"
    {...props}
  />
);

export type ToggleButtonProps = {
  toggle: () => void;
};

export default function ToggleButton(props: ToggleButtonProps) {
  return (
    <>
      <button
        onClick={props.toggle}
        className="group bg-transparentfocus:outline-none "
        aria-label="ToggleNavBar"
      >
        <svg
          className="dark:fill-white fill-black"
          width="23"
          height="23"
          viewBox="0 0 23 23"
        >
          <Path
            variants={{
              closed: { d: "M 2 2.5 L 20 2.5" },
              open: { d: "M 3 16.5 L 17 2.5" },
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: "M 2 16.346 L 20 16.346" },
              open: { d: "M 3 2.5 L 17 16.346" },
            }}
          />
        </svg>
      </button>
    </>
  );
}
