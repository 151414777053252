import { useEffect, useRef, useState } from "react";
import MobileNavBar from "./MobileNavBar";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import ToggleButton from "./ToggleButton";
import DarkModeToggle from "./DarkModeToggle";
import MainLogo from "./MainLogo";

export default function NavBar() {
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const [isOpen, toggleOpen] = useCycle(false, true);

  const [mobileMode, setMobileMode] = useState<boolean>(false);
  const outerWidth = useRef<number | null>(null);

  const mobileNavRef = useRef<HTMLDivElement | null>(null);
  const mobileNavButtonRef = useRef<HTMLDivElement | null>(null);

  function toggle() {
    toggleOpen();
  }

  const checkResize = () => {
    if (window.outerWidth === outerWidth.current) {
      return;
    }
    outerWidth.current = window.outerWidth;

    if (window.outerWidth < 768) {
      setMobileMode(true);
    } else {
      setMobileMode(false);
      toggleOpen(0);
    }
  };

  const checkScroll = () => {
    // Check if the user has scrolled down
    if (window.pageYOffset > 0) {
      // User has scrolled down

      document
        .getElementById("bigNav")
        ?.classList.add(
          "border-slate-300",
          "bg-slate-200",
          "dark:border-slate-900",
          "dark:bg-slate-800"
        );

      return;
    }
    document
      .getElementById("bigNav")
      ?.classList.remove(
        "border-slate-300",
        "bg-slate-200",
        "dark:border-slate-900",
        "dark:bg-slate-800"
      );
  };

  const closeMobileNav = (e: MouseEvent) => {
    if (!isOpen) {
      document.getElementById("screen")?.classList.remove("offLimits");

      return;
    }
    if (
      mobileNavRef.current &&
      !mobileNavRef.current.contains(e.target as Node) &&
      !(
        mobileNavButtonRef.current &&
        mobileNavButtonRef.current.contains(e.target as Node)
      )
    ) {
      toggleOpen(0);

      //Give the rest of the site a dark look while the mobile menu is open
      document.getElementById("screen")?.classList.add("offLimits");

      console.log("closing nav from outside click");
    }
  };

  useEffect(() => {
    checkResize();
    window.addEventListener("resize", checkResize);
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkResize);
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  useEffect(() => {
    console.log("is open is " + isOpen);
    if (isOpen) {
      document.body.addEventListener("click", closeMobileNav);
      document.getElementById("screen")?.classList.add("offLimits");
    } else {
      document.body.removeEventListener("click", closeMobileNav);
      document.getElementById("screen")?.classList.remove("offLimits");
    }

    return () => {
      document.body.removeEventListener("click", closeMobileNav);
    };
  }, [isOpen]);
  const variants = {
    open: {},
    closed: {},
  };
  return (
    <>
      {mobileMode && (
        <div className="fixed top-0 w-screen">
          <AnimatePresence>
            {isOpen && (
              <>
                <div className="absolute right-10 top-0 w-64">
                  <motion.div
                    initial={{ x: 300 }}
                    animate={{ x: 60 }}
                    exit={{ x: 400 }}
                    transition={{
                      type: "spring",
                      stiffness: 500, // Adjust the stiffness value (higher = faster)
                      damping: 30, // Adjust the damping value (lower = faster)
                    }}
                  >
                    <motion.nav
                      initial={"closed"}
                      animate={isOpen ? "open" : "closed"}
                      variants={variants}
                      ref={mobileNavRef}
                    >
                      <MobileNavBar />
                    </motion.nav>
                  </motion.div>
                </div>
              </>
            )}
          </AnimatePresence>

          <>
            <div className="w-3/5" />
            <div className="w-1/5">
              <motion.div initial={isOpen} animate={isOpen ? "open" : "closed"}>
                <div
                  className="absolute right-5 top-5"
                  ref={mobileNavButtonRef}
                >
                  <ToggleButton toggle={() => toggle()} />
                </div>
              </motion.div>
            </div>
            <div className="w-1/5" />
          </>
        </div>
      )}

      <div className="p-5 md:mt-4">
        {mobileMode && <MainLogo />}

        {!mobileMode && (
          <>
            <nav
              id="bigNav"
              className="fixed inset-x-0 top-0 w-screen pb-6 pt-6 duration-200 ease-in-out"
            >
              <div className="flex flex-row items-center justify-center	text-base md:ml-4  md:py-1  lg:ml-8 lg:pl-4">
                <MainLogo />

                <a href="/home" className="btn mr-5">
                  <span>
                    <h2>Home</h2>
                  </span>
                </a>
                <a href="/services" className="btn mr-5">
                  <h2>Services</h2>
                </a>

                <a href="/about" className="btn  mr-5">
                  <h2>About</h2>
                </a>

                <a href="/Contact" className="btn mr-5">
                  <h2>Contact</h2>
                </a>
                <div className="w-4 p-4 ">
                  <DarkModeToggle />
                </div>

                <div className="ml-10 md:p-1" />
                <a
                  href="/Contact"
                  className=" rounded-full  border-2  border-black p-3 pl-5 pr-5 text-center dark:border-white md:mt-4 lg:mt-0"
                >
                  <h2>Get Started</h2>
                </a>
              </div>
            </nav>
          </>
        )}
      </div>
    </>
  );
}
