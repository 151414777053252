import { lazy } from "react";
import { Navigate } from "react-router-dom";

// const  LogsManagement  = lazy(()=> import('logs/LogsManagement'));

const Privacy = lazy(() => import("routes/Privacy"));

const About = lazy(() => import("routes/About/About"));

const Landing = lazy(() => import("routes/Landing/Landing"));

export const publicRoutes = [
  { path: "/", element: <Landing /> },

  { path: "/privacy", element: <Privacy /> },
  { path: "/about", element: <About /> },

  { path: "*", element: <Navigate to="." /> },
];
