import * as React from "react";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 20,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 60,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export type MobileMenuItemProps = {
  i: number;
  name: string;
};
export const MobileMenuItem = (props: MobileMenuItemProps) => {
  const style = { border: `2px solid ${colors[props.i]}` };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <a href={props.name} className="btn" style={style}>
        {props.name}
      </a>
    </motion.li>
  );
};
