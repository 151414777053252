import React, { Suspense, useEffect, useState } from "react";

//Nodemodules/react-scripts/config/webpack.config.js
//https://www.npmjs.com/package/react-toastify
import "react-toastify/dist/ReactToastify.css";

//ICONS//
//https://react-icons.github.io/react-icons/icons?name=fa6

//SVG DRAWING//
//https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths

import { BrowserRouter as Router } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { useAppDispatch } from "api/hooks";
import { store } from "./api/store";
import { AppRoutes } from "providers";
import NavBar from "components/navigation/NavBar";
const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["dark"]);
  const dispatch = useAppDispatch();

  store.subscribe(() => {
    const state = store.getState();
    document.cookie = `dark=${state.theme["darkMode"]}`;
  });

  useEffect(() => {
    let isDarkModeCookie: boolean = cookies["dark"] === "true" ? true : false;
    dispatch({ type: "theme/toggleTheme", payload: isDarkModeCookie });
  }, []);

  return (
    <>
      <Router>
        <div id="screen"></div>

        <NavBar />
        <Suspense fallback={<div>Loading...</div>}>
          <div className="z-0 px-4 py-4 dark:bg-slate-900">
            <AppRoutes />
          </div>
        </Suspense>
      </Router>

      <ToastContainer autoClose={2000} />
    </>
  );
};

export default App;
